// @ts-nocheck
import React from 'react';
import Undergraduate from './Content/Undergraduate';
import Xmas from './Content/Xmas';
import AutumnWatch from './Content/AutumnWatch';
import Rayne from './Content/Rayne';
import Sparkles from './Content/Sparkles';
import Birdwatch from './Content/Birdwatch';
import CoffeeRoulette from './Content/CoffeeRoulette';
import ExecOffice from './Content/ExecOffice';
import LunarNewYear from './Content/LunarNewYear';
import SpringAtWarwick from './Content/SpringWarwick';
import Shrubsy from './Content/Shrubsy';
import Pride from './Content/Pride';
import Summer from './Content/Summer';
import PgCampaign from './Content/PgCampaign';
import WarwickStudentPanel from './Content/WarwickStudentPanel';
import ConnectHoardings from './Content/ConnectHoardings';
import WildlifeTrust from './Content/WildlifeTrust';
import ElevatingEmails from './Content/ElevatingEmails';
import UcasExhibition from './Content/UcasExhibition';

const Modal = ({ closeModal, data, isMobile }) => {
    const MODALS = {
        BIRDWATCH: Birdwatch,
        COFFEE_ROULETTE: CoffeeRoulette,
        EXEC_OFFICE: ExecOffice,
        UNDERGRAD: Undergraduate,
        XMAS: Xmas,
        AUTUMN_WATCH: AutumnWatch,
        RAYNE: Rayne,
        SPARKLES: Sparkles,
        LUNAR_NEW_YEAR: LunarNewYear,
        SPRING_AT_WARWICK: SpringAtWarwick,
        SHRUBSY: Shrubsy,
        PRIDE: Pride,
        SUMMER: Summer,
        PG_CAMPAIGN: PgCampaign,
        WARWICK_STUDENT_PANEL: WarwickStudentPanel,
        CONNECT_HOARDINGS: ConnectHoardings,
        WILDLIFE_TRUST: WildlifeTrust,
        ELEVATING_EMAILS: ElevatingEmails,
        UCAS_EXHIBITION: UcasExhibition
    };
    const Component = MODALS[data.id]

    return (
        <div className={isMobile ? "animate-modal_show fixed block overflow-auto w-full h-screen top-0 bottom-0 pb-16 bg-slate-50 z-10" : "animate-modal_show left-[26.669%] fixed block overflow-auto w-8/12 h-screen top-0 bottom-0 pb-16 bg-slate-50 z-10"}>
            <div className='fixed top-2 right-0 float-right z-30'>
                <div className="sticky cursor-pointer float-right p-3 mr-1" onClick={() => {
                    closeModal()
                    Array.from(document.querySelectorAll('body')).forEach(
                        (el) => el.classList.remove('modal-open')
                    );
                }}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" strokeWidth="1.5" stroke="black" class="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                    </svg>
                </div>
            </div>
            <div className='flex flex-col items-center justify-center text-cyan-50'>
                <Component data={data} isMobile={isMobile} />
            </div>
        </div>
    )
}

export default Modal
