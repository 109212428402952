// @ts-nocheck
import React from 'react'

// #3C1053

const tagColourMap = {
    "University of Warwick": "#3C1053",
    "Design": "#0F172A",
    "Animation": "#D26568",
    "Illustration": "#5B7A70"
}

function Tags({ tags }) {
    const slicedTags = tags.slice(1)
    return (
        <div className='w-full flex flex-wrap justify-start gap-2'>
            {slicedTags.map(tag => {
                var backgroundStyle = {
                    backgroundColor: tagColourMap[tag]
                }
                return (
                    <div className='rounded-lg px-2 text-xs font-semibold' style={backgroundStyle}>{tag.toLowerCase()}</div>
                )
            })}
        </div>
    )
}

export default Tags