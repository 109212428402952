// @ts-nocheck
import React, { useState } from 'react';
import { CATEGORIES } from '../../constants/categories';

function MobileNavBar({ selectedCategory, setCategoryHandler, setModal }) {
    const [isShowingMenu, setShowMenu] = useState(false);

    const genericHamburgerLine = `h-1 w-6 my-1 rounded-full bg-white transition ease transform duration-300`;

    const emailAddress = "laueliz1@hotmail.co.uk"
    const emailSubject = "Contact"
    const mailLocation = `mailto:${emailAddress}?subject=${emailSubject}`
  
    return (
        <div className='h-min'>
            <nav className='sticky top-0 flex w-screen h-12 text-white text-right bg-black'>
                <button
                    className="flex flex-col h-12 w-12 ml-auto order-2 justify-center items-center group"
                    onClick={() => setShowMenu(!isShowingMenu)}
                >
                    <div
                        className={`${genericHamburgerLine} ${isShowingMenu
                            ? "rotate-45 translate-y-3 opacity-50 group-hover:opacity-100"
                            : "opacity-50 group-hover:opacity-100"
                            }`}
                    />
                    <div className={`${genericHamburgerLine} ${isShowingMenu ? "opacity-0" : "opacity-50 group-hover:opacity-100"}`} />
                    <div
                        className={`${genericHamburgerLine} ${isShowingMenu
                            ? "-rotate-45 -translate-y-3 opacity-50 group-hover:opacity-100"
                            : "opacity-50 group-hover:opacity-100"
                            }`}
                    />
                </button>
            </nav>
            {isShowingMenu &&
                    <div className="nav-bar-animate flex flex-col bg-black text-white p-8 justify-center items-start gap-2 md:hidden">
                    <div className='select-none cursor-pointer'>
                        <a href="https://www.instagram.com/shrubsyart/" target="_blank">
                        <div className='flex flex-col mb-2' onClick={() => {
                            window.location.href = mailLocation
                        }}>
                            <div class="group relative w-max" >
                                <h3>contact</h3>
                                <span
                                    class=" text-black pointer-events-none p-4 rounded-md bg-slate-200 absolute -top-4 left-24 w-max opacity-0 transition-opacity group-hover:opacity-100"
                                >
                                    {emailAddress}
                                </span>
                            </div>
                        </div>
                        <span>
                            instagram
                        </span>
                        </a>
                    </div>
                        <div className="flex justify-center opacity-20 w-full">
                            <hr className={`h-px w-3/4 my-2 bg-white border-0`}/>
                        </div>
                        <h3 className={selectedCategory === CATEGORIES.ALL ? 'text-blue-400' : ''} onClick={() => setCategoryHandler(CATEGORIES.ALL)}>all</h3>
                        <h3 className={selectedCategory === CATEGORIES.DESIGN ? 'text-blue-400' : ''} onClick={() => setCategoryHandler(CATEGORIES.DESIGN)}>design</h3>
                        <h3 className={selectedCategory === CATEGORIES.ANIMATION ? 'text-blue-400' : ''} onClick={() => setCategoryHandler(CATEGORIES.ANIMATION)}>animation</h3>
                        <h3 className={selectedCategory === CATEGORIES.ILLUSTRATION ? 'text-blue-400' : ''} onClick={() => setCategoryHandler(CATEGORIES.ILLUSTRATION)}>illustration</h3>
                    </div>
            }
        </div>
    );
}

export default MobileNavBar