//@ts-nocheck
import React from 'react'
import Divider from '../../Divider/Divider'
import ButtonWithIcon from '../../Buttons/ButtonWithIcon';
import Tags from '../../Tags/Tags';

function ElevatingEmails({ data, isMobile }) {
  return (
    <>
        <video autoPlay loop muted webkit-playsinline={true} playsInline>
        <source src={data.images[0]} type="video/mp4" />
        </video>
      
      <div className={'sm:w-1/4 md:w-3/4'}>
        <Divider width={'full'} />
        <div className={isMobile ? "px-10 py-10" : "pb-10"}>
          <div className='text-left'>
            <h1 class="mb-2 text-3xl font-extrabold leading-none tracking-tight text-slate-800 md:text-4xl lg:text-5xl">
                {data.title}</h1>
            <div className='mb-2'>
                <Tags tags={data.tags} />
            </div>
            <p class="mb-6 text-sm font-normal text-slate-800">
              {data.description}</p>
            <ButtonWithIcon label={"See one live"} link={data.external[0]} />
          </div>
        </div>

        <Divider width={'full'} />

        <video autoPlay loop muted webkit-playsinline={true} playsInline>
            <source src={data.images[1]} type="video/mp4" />
        </video>

        <Divider width={'full'} />

        <video autoPlay loop muted webkit-playsinline={true} playsInline>
            <source src={data.images[2]} type="video/mp4" />
        </video>
      </div>
    </>
  )
}

export default ElevatingEmails